import { createRouterLayout } from "vue-router-layout";
import { createRouter, createWebHistory } from "vue-router";

const RouterLayout = createRouterLayout((layout) => {
  return import("@/layouts/" + layout + ".vue");
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: RouterLayout,
      children: [
        {
          name: "index",
          path: "",
          component: () => import("@/pages/index"),
          children: [
            {
              name: "home",
              path: "",
              component: () => import("@/components/organisms/Home"),
            },
            {
              name: "download",
              path: "download",
              component: () => import("@/components/organisms/DownloadList"),
            },
          ]
        },
      ]
    }
  ]
});

export default router;