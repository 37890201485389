import { createApp } from "vue";
import App from "./App.vue";
import Vuex from 'vuex'
import router from "./router";
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createGtm } from '@gtm-support/vue-gtm';
const app = createApp(App)

app.use(router)
app.use(Vuex)
app.use(VueAxios, axios)
app.use(
  createGtm({
    id: "GTM-TKCQBF4K",
    vueRouter: router,
    trackViewEventProperty: 'page-load'
  })
);
app.mount("#app")

